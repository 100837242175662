.main-page {
  padding-left: 20px;
  padding-right: 20px;
}

.orangeBackground {
  position: relative;
}

.Imagecontent {
  position: absolute;
  top: 30px;
  left: 80px;
  color: white;
}

.formPlacement {
  position: absolute;
  top: 80px;
  right: 80px;
  color: white;
}
.bootcampOffers {
  margin-top: 50px;
  margin-left: 20px;
}

.learnthrough {
  margin-top: 50px;
  margin-left: 20px;
}

.information-box {
  background-color: #f3f3f3;
}

.buttonGroup {
  padding: 5px;
  background-color: #f2711c;
}

.support-card {
  background-color: #f5faff;
  color: black;
}
