.footer {
  clear: both;
  position: relative;
  height: 10px;
  margin-top: -200px;
  width: 100%;
  background-color: white;
  color: grey;
  text-align: center;
}
