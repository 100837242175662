.Imagecontent {
  position: absolute;
  color: white;
}

.orangeBackground {
  position: relative;
}

.mobile-page {
  margin-left: 20px;
  margin-right: 20px;
}

.conceptToLearn {
  color: #f2711c;
}

.support-card {
  background-color: #f5faff;
}
